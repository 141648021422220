<template>
<div>
  
</div>
</template>

<script>

export default {
    props: ["countriesArray"],
    data: () => ({
      driver: {},
      photoFile: null,
      file: null,
      fileRules: [
        v => !!v || 'File is required',
        v => !v || v.size < 2 * 1024 * 1024 || 'File size should be less than 2MB',
        v => !v || ['image/jpeg', 'image/png', 'image/gif'].includes(v.type) || 'File must be an image'
      ],
      fileIcon: 'photo_camera',
      searchingOrgs: false,
      organisations: {
        total: 0,
        data: [],
      },
    }),
    computed: {
  },
    watch: {
      file(newValue) {
      if (newValue) {
        this.onFileChanged({ target: { files: [newValue] } })
      } else {
        this.driver.avatar = null
      }
      },
      
      async searchOrganisations(val) {
      if (this.searchingOrgs) {
        clearTimeout(this.organisationTimeout);
      }
      if (val && val.length > 2) {
        this.organisationTimeout = setTimeout(async () => {
          this.organisations = await this.$API.getOrganisationsPOI({
            params: {
              search: val,
              limit: 15,
            },
          });
        }, 150);
      } else {
        clearTimeout(this.organisationTimeout);
      }
    },
    },

    methods: {
    async createDriver() {
      let driver = {
        firstName: this.driver.firstName,
        lastName: this.driver.lastName,
        idNumber: this.driver.idNumber,
        emailAddress: this.driver.emailAddress,
        e164: this.driver.dialCode.e164,
        e164Id: this.driver.dialCode.id,
        phoneNumber: this.driver.phoneNumber,
        company: this.driver.organisation.name,
        organisationId: this.driver.organisation.id,
        nationality: this.driver.nationality.name,
        isLiveChat: false,
        avatar: this.driver.avatar,
      }
      let result = await this.$API.createDriver(driver);
      if(result) {
        if(this.photoFile) {
        this.photoUploaded(this.photoFile, result.id);
      }
      this.$message({
                    type: 'success',
                    message: 'Successfully registered new driver!'
      })
      }
    },
    // PHOTO UPLOAD /////////////////////////////////////////////////
    async photoUploaded(file, id) {
      let url = await URL.createObjectURL(file);
      this.toDataUrl(url, (data) => {
          this.uploadPhoto({
              name: file.name,
              data: data,
              type: file.type,
              driverId: id
          });
      });
    },

    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
              callback(reader.result);
          };
          reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },

    async uploadPhoto(obj) {
      let res = await this.$API.uploadDriverAvatar(obj);
      this.$message.success('Avatar update successfull!');
    },
    openFileExplorer() {
      document.getElementById("imageUpload").click();
    },
    onFileChanged(event) {
      const file = event.target.files[0]
      this.photoFile = file
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.driver.avatar = reader.result
        }
        reader.readAsDataURL(file)
      }
    },
    // PHOTO UPLOAD /////////////////////////////////////////////////


    countryChange() {
      this.$refs.countrySelect.blur();
    },


    },
}
</script>

<style scoped>
  .avatar-uploader  {
  border: 1px dashed #d9d9d9;
  border-radius: 200px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 3vh 0 0 0;
}
.avatar-uploader:on-hover {
  border-color: #c56f35;
  filter: blur(2px);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}
.avatar {
  width: 200px;
  height: 200px;
  display: block;
}

.on-hover {
  opacity: 0.6;
}
.icon-add, .icon-edit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .icon-add {
    font-size: 48px;
    opacity: 0.5;
  }
  .icon-edit {
    font-size: 28px;
  }
</style>